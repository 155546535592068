import React from 'react';
import './ds.css';
import logo from './new gifs/5,6,7,8/5x3/logo.png';

function App() {
  return (
    <div className="App">   
         <img src={logo} alt="logo"></img>
          <div>
            <p id="notice">Stay tuned for an all new DateSpotz coming soon!</p>
          </div>

    </div>
    
  );
}

export default App;
